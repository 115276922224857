import React, { useState, useRef, Fragment } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { ThemeProvider } from 'styled-components';
import Sticky from 'react-stickynode';
import styled from 'styled-components';
import { DrawerProvider } from 'common/src/contexts/DrawerContext';
import { saasModernTheme } from 'common/src/theme/saasModern';
import { ResetCSS } from 'common/src/assets/css/style';
import { GlobalStyle, ContentWrapper } from '../containers/sassModern.style';

import BannerSectionSmall from '../containers/BannerSmall';
import Navbar from '../containers/Navbar';
import Footer from '../containers/Footer';
import SEO from '../components/seo';
import Box from '../../../common/src/components/Box';
import Container from '../../../common/src/components/UI/Container';
import DownloadSection from '../containers/DownloadSection';
import CTASection from '../containers/CTA';
import Input from '../../../common/src/components/Input';
import { useTranslation, Trans } from 'react-i18next';

const Content = styled.div`
  font-family: 'Roboto', sans-serif;
  line-height: 2;
  color: #343d48cc;

  a {
    color: #343d48cc;
    text-decoration: underline;
  }

  ul {
    margin-left: 40px;
  }

  li {
    list-style-type: disc;
  }

  h2 {
    font-weight: 500;
  }
`;

export default ({ pageContext }) => {
  const { t } = useTranslation();
  const inputRef = useRef(null);

  const [password, setPassword] = useState('');
  const [characters, setCharacters] = useState('');
  const [time, setTime] = useState('instant');
  const [rating, setRating] = useState('');
  const [entropy, setEntropy] = useState(0);
  const [shannonEntropy, setShannonEntropy] = useState(0);
  const [passwordLength, setPasswordLength] = useState(0);
  const [includesSpecial, setIncludesSpecial] = useState(true);
  const [includesNumbers, setIncludesNumbers] = useState(true);
  const [
    includesLowercaseCharacters,
    setIncludesLowercaseCharacters,
  ] = useState(true);
  const [
    includesUppercaseCharacters,
    setIncludesUppercaseCharacters,
  ] = useState(true);

  function hasNumber(myString) {
    return /\d/.test(myString);
  }

  function hasUppercase(myString) {
    return /[A-Z]/.test(myString);
  }

  function hasLowercase(myString) {
    return /[a-z]/.test(myString);
  }

  function hasSpecial(myString) {
    return /[ !@#$%^&*§()_+\-=[\]{};':"\\|,.<>/?]/.test(myString);
  }

  const calculateShannonEntropy = (password) => {
    // Source: https://gist.github.com/jabney/5018b4adc9b2bf488696
    const len = password.length;

    const frequencies = Array.from(password).reduce(
      (freq, c) => (freq[c] = (freq[c] || 0) + 1) && freq,
      {}
    );

    return Object.values(frequencies).reduce(
      (sum, frequency) => sum - (frequency / len) * Math.log2(frequency / len),
      0
    );
  };

  const onPasswordChange = (newPassword) => {
    var specials = '-_#+*!§$%&=?@,.;:\'~"/(){}[]\\';
    var numbers = '0123456789';
    var uppercaseChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    var lowercaseChars = 'abcdefghijklmnopqrstuvwxyz';

    const passwordLength = newPassword.length;
    const containsNumbers = hasNumber(newPassword);
    const containsUppercase = hasUppercase(newPassword);
    const containsLowercase = hasLowercase(newPassword);
    const containsSpecial = hasSpecial(newPassword);

    let characters = '';
    if (containsNumbers) {
      characters = characters + numbers;
    }
    if (containsUppercase) {
      characters = characters + uppercaseChars;
    }
    if (containsLowercase) {
      characters = characters + lowercaseChars;
    }
    if (containsSpecial) {
      characters = characters + specials;
    }

    const shannonEntropy = calculateShannonEntropy(newPassword);
    const entropy = calculateShannonEntropy(characters);

    let rating = t('very week, do never use these passwords');
    let time = t('instant');

    if (entropy * passwordLength > 128) {
      rating = t(
        'very strong (128+ bits), suitable against offline brute force attacks, e.g. disc encryption'
      );
      time = t('never');
    } else if (entropy * passwordLength > 75) {
      rating = t(
        'strong (75+ bits), suitable protection for web services like mail accounts where rate limits are in place'
      );
      // 75 bits ~ 100 years. Source https://en.wikipedia.org/wiki/Password_strength 2011 72 bits -> 125 years
      const years = 100 * Math.pow(2, entropy * passwordLength - 75);
      time = Math.round(years) + ' ' + t('years');
    } else if (entropy * passwordLength > 56) {
      rating = t(
        'medium (56+ bits), might be tolerable if attackers have no option to brute force attacks offline. e.g. encryption of the mobile with a secure element'
      );
      const days = 365 * 100 * Math.pow(2, entropy * passwordLength - 75);
      if (days > 365) {
        const years = Math.floor(days / 365);
        const leftoverDays = days - years * 365;
        time =
          years +
          ' ' +
          t('years') +
          ' ' +
          Math.round(leftoverDays) +
          ' ' +
          t('days');
      } else if (days > 1) {
        time = Math.round(days) + ' ' + t('days');
      } else {
        time = Math.round(days * 24) + ' ' + t('hours');
      }
    } else if (entropy * passwordLength > 37) {
      rating = t(
        'week (37+ bits), maybe as a temporary initial password that a user has to change immediately'
      );
      time =
        Math.round(
          24 * 60 * 60 * 365 * 100 * Math.pow(2, entropy * passwordLength - 75)
        ) +
        ' ' +
        t('seconds');
    }

    setTime(time);
    setRating(rating);
    setCharacters(characters);
    setPasswordLength(passwordLength);
    setIncludesNumbers(containsNumbers);
    setIncludesUppercaseCharacters(containsUppercase);
    setIncludesLowercaseCharacters(containsLowercase);
    setIncludesSpecial(containsSpecial);
    setShannonEntropy(shannonEntropy);
    setEntropy(entropy);
    setPassword(newPassword);
  };

  const Data = useStaticQuery(graphql`
    query {
      saasModernJson {
        PASSWORD_MANAGER_MORE {
          title
          description
          image {
            publicURL
          }
        }
        CROSS_PLATFORM_CLIENTS {
          title
          description
          url
          installGuideUrl
          icon {
            publicURL
          }
        }
      }
      bannerImage: file(
        relativePath: { eq: "image/saasModern/password-generator.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1170, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `);
  return (
    <ThemeProvider theme={saasModernTheme}>
      <Fragment>
        <SEO
          title={t(
            'Password Strength Tester - Check the security of passwords'
          )}
          lang={pageContext.lang}
          description={t(
            'Check your password strength and test their security.'
          )}
        />

        <ResetCSS />
        <GlobalStyle />

        <ContentWrapper>
          <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
            <DrawerProvider>
              <Navbar pageContext={pageContext} />
            </DrawerProvider>
          </Sticky>
          <BannerSectionSmall
            titleText={t('Password Strength Test')}
            descriptionText={t(
              'Check your password strength and test their security.'
            )}
            image={Data.bannerImage.childImageSharp.fluid}
          />
          <Box as="section" id="legal_notice_section">
            <Container>
              <Content>
                <Input
                  label={t('Password')}
                  value={password}
                  inputType="password"
                  passwordShowHide={true}
                  innerRef={inputRef}
                  onChange={(newPassword) => {
                    onPasswordChange(newPassword);
                  }}
                />
                <p>
                  <Trans i18nKey="password_analysis_message">
                    The analysis of the password happens in your browser, so no
                    data is sent to any server. Start the analysis by typing
                    your password here.
                  </Trans>
                </p>
                <h2>{t('Password Strength Report:')}</h2>

                {passwordLength === 0 && (
                  <React.Fragment>
                    <b>
                      <Trans i18nKey="enter_password_prompt">
                        Enter a password above to see an analysis of your
                        password's strength.
                      </Trans>
                    </b>
                  </React.Fragment>
                )}

                {passwordLength > 0 && (
                  <React.Fragment>
                    <p>
                      {t('password_strength_detail_length', { passwordLength })}
                    </p>
                    <p>
                      {t(
                        'password_strength_detail_include_lowercase_characters',
                        {
                          includesLowercaseCharacters: includesLowercaseCharacters
                            ? t('Yes')
                            : t('No'),
                        }
                      )}
                    </p>
                    <p>
                      {t('password_strength_detail_include_upper_characters', {
                        includesUppercaseCharacters: includesUppercaseCharacters
                          ? t('Yes')
                          : t('No'),
                      })}
                    </p>
                    <p>
                      {t('password_strength_detail_includes_special', {
                        includesSpecial: includesSpecial ? t('Yes') : t('No'),
                      })}
                    </p>
                    <p>
                      {t('password_strength_detail_include_numbers', {
                        includesNumbers: includesNumbers ? t('Yes') : t('No'),
                      })}
                    </p>
                    <p>
                      {t('password_strength_detail_entropy', {
                        entropyBits:
                          Math.round(entropy * passwordLength * 100) / 100,
                        shannonEntropyBits:
                          Math.round(shannonEntropy * passwordLength * 100) /
                          100,
                      })}
                    </p>
                    <p>{t('password_strength_detail_rating', { rating })}</p>
                    <p>{t('password_strength_detail_time', { time })}</p>
                    <h2>
                      {t('How is the entropy of the password calculated:')}
                    </h2>
                    <ul>
                      <li>
                        <Trans i18nKey="character_pool_message">
                          It appears that you have chosen characters from the
                          following pool of characters:
                          <p style={{ fontFamily: "'Fira Code', monospace" }}>
                            {{ characters }}
                          </p>
                        </Trans>
                      </li>
                      <li>
                        {t('entropy_per_character_message', {
                          entropyBits:
                            Math.round(entropy * passwordLength * 100) / 100,
                        })}
                      </li>
                      <li>
                        {t('total_entropy_message', {
                          totalEntropy:
                            Math.round(entropy * passwordLength * 100) / 100,
                          passwordLength: passwordLength,
                        })}
                      </li>
                      <li>
                        <Trans i18nKey="entropy_rating_message">
                          The rating is calculated by the entropy afterwards.
                          Passwords with 75 or more bits are suitable to protect
                          your online accounts and are strong enough to handle
                          the increased computing power over the next 20 years.
                          Everything above 128 bits is secure and protects even
                          against distributed offline attacks, e.g., brute
                          force.
                        </Trans>
                      </li>
                      <li>
                        <Trans i18nKey="time_calculation_message">
                          For the time calculation, we assume that 75 bits would
                          take about 100 years (source{' '}
                          <a href="https://en.wikipedia.org/wiki/Password_strength">
                            Wikipedia
                          </a>{' '}
                          states numbers from 2011 with 72 bits corresponding to
                          125 years).
                        </Trans>
                      </li>
                      <li>
                        <Trans i18nKey="password_warning_message">
                          Warning: We assume here that the password does not
                          appear in any dictionary, doesn't contain names, or
                          other information that can be linked to you (e.g.,
                          birthdays, pets' names, ...).
                        </Trans>
                      </li>
                    </ul>
                  </React.Fragment>
                )}

                <h2>{t('General tips to improve your password strength:')}</h2>

                <p>
                  <Trans i18nKey="password_tips_intro">
                    Passwords protect everything. Your identity, financial data,
                    health records, company secrets, conversations, and much
                    more. It is mandatory to use strong and secure passwords,
                    but what is a strong and secure password, and how do you
                    come up with one? Here are a few tips:
                  </Trans>
                </p>
                <ul>
                  <li>
                    <p>
                      <Trans i18nKey="strong_password_length">
                        A strong password is at least 16 characters long and
                        preferably includes numbers, special characters, lower
                        and uppercase symbols.
                      </Trans>
                    </p>
                  </li>
                  <li>
                    <p>
                      <Trans i18nKey="unique_passwords_tip">
                        Passwords should be unique and never reused for multiple
                        websites. You do not want your bank account or email
                        account compromised because you used the same password
                        for a game you tried once. Use an{' '}
                        <a href="/enterprise-password-manager/">
                          enterprise password manager
                        </a>{' '}
                        like Psono to store and manage all these passwords.
                      </Trans>
                    </p>
                  </li>
                  <li>
                    <p>
                      <Trans i18nKey="avoid_guessable_info">
                        There is certain information an attacker can easily
                        guess, and as such, you should avoid names of pets,
                        family members, or friends.
                      </Trans>
                    </p>
                  </li>
                  <li>
                    <p>
                      <Trans i18nKey="second_factor_tip">
                        A second factor like Google Authenticator, Authy,
                        Yubikey will help to protect your accounts even if your
                        password ever gets compromised.
                      </Trans>
                    </p>
                  </li>
                  <li>
                    <p>
                      <Trans i18nKey="avoid_personal_data_in_passwords">
                        Do not use zip codes, house and phone numbers,
                        birthdays, and sequences from your ID or social security
                        number to improve your password. This type of data
                        provides no additional security.
                      </Trans>
                    </p>
                  </li>
                  <li>
                    <p>
                      <Trans i18nKey="avoid_keyboard_patterns">
                        Avoid keyboard patterns like QWERTY, 123456, and simple
                        modifications to dictionary words like p4$$word! ...
                        Secure passwords are random and look like
                        qs^?#jD3Ym8rB&D or +Eph6/.q(7t*TjZ
                      </Trans>
                    </p>
                  </li>
                  <li>
                    <p>
                      <Trans i18nKey="update_software_tip">
                        Regular software updates are key to protect your systems
                        from malware like keyloggers and Trojans. Many bugs are
                        fixed weeks before attackers start exploiting them.
                      </Trans>
                    </p>
                  </li>
                  <li>
                    <p>
                      <Trans i18nKey="avoid_biometrics_tip">
                        Any password mechanism that cannot be easily changed yet
                        cloned, like your iris or fingerprint, should only be
                        used as a second factor and avoided as the primary means
                        of security.
                      </Trans>
                    </p>
                  </li>
                  <li>
                    <p>
                      <Trans i18nKey="trustworthy_computer_tip">
                        You should only log in on computers that you trust. An
                        attacker might have installed malware on your friends'
                        or family members' computer.
                      </Trans>
                    </p>
                  </li>
                  <li>
                    <p>
                      <Trans i18nKey="avoid_untrusted_wifi">
                        Untrusted Wi-Fi hotspots and free VPN services are
                        common ways for attackers to steal your passwords.
                      </Trans>
                    </p>
                  </li>
                  <li>
                    <p>
                      <Trans i18nKey="use_secure_protocols">
                        Only use secure protocols like HTTPS, SFTP, and FTPS.
                        Always check which protocol you use before entering any
                        sensitive information.
                      </Trans>
                    </p>
                  </li>
                  <li>
                    <p>
                      <Trans i18nKey="auto_lock_devices_tip">
                        Configure auto-lock for your devices. A good time would
                        be 30 seconds or less. Create a habit of locking your
                        device when you leave it, even if it's just for a short
                        period.
                      </Trans>
                    </p>
                  </li>
                  <li>
                    <p>
                      <Trans i18nKey="check_breached_passwords">
                        You should not use a password that has been part of a
                        previous data breach. You can check your passwords on{' '}
                        <a
                          href="https://haveibeenpwned.com/"
                          rel="nofollow noopener"
                        >
                          haveibeenpwned.com
                        </a>{' '}
                        to see if they have been breached.
                      </Trans>
                    </p>
                  </li>
                  <li>
                    <p>
                      <Trans i18nKey="never_share_passwords">
                        Never tell anyone your password. Always create separate
                        accounts if possible. Keep in mind that administrators
                        and support staff have other means to access your
                        account and will never ask you for your password.
                      </Trans>
                    </p>
                  </li>
                </ul>
                <p>{t('Need help to follow these tips?')}</p>
                <DownloadSection
                  titleText={t(
                    'Use Psono across multiple device types, computers and mobiles'
                  )}
                  descriptionText={t('Cross-platform password manager')}
                  downloadItems={Data.saasModernJson.CROSS_PLATFORM_CLIENTS}
                />
                <CTASection
                  content={t('Try Psono for free?')}
                  buttonLink="https://www.psono.pw/"
                  buttonTitle={t('ACCESS PSONO FREE')}
                />
              </Content>
            </Container>
          </Box>

          <Footer pageContext={pageContext} />
        </ContentWrapper>
      </Fragment>
    </ThemeProvider>
  );
};
